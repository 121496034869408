import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import * as b from "../components/bootstrap.module.css";
import Comments from "../components/comments";
import CommentsForm from "../components/comments-form";
import Thumbnail from "../components/thumbnail";
import Icon from "../components/icon.tsx";
import Out, { OutProductRedirect } from "../components/out-product";
import AdvDis from "../components/adv-dis";
import BlogContent from "../components/blog-content";
import Translate from "../components/translate"
import TableOfContents from "../components/table-of-contents";
import Breadcrumbs from "../components/breadcrumbs";
import Layout from "../components/layout";
import resolveLocalfirmUrl from "../utils/urls/resolve-localfirm-url";
import SimilarProducts from "../components/similar-products";

import BaseHead from '../components/base-head';
import siteMetdata from "../utils/site-metadata";

export const Head = ({ ...props }) => (
  <BaseHead 
    {...props} 
    title={props.data?.mysqlLocalfirms?.name && `Pośrednik kredytowy ${props.data.mysqlLocalfirms.name} - oferta i opinie | akredo.pl`} 
    description={props.data?.mysqlLocalfirms?.intro_description || `Wybranie najtańszego kredytu może być problematyczne. Aby mieć pewność najlepszej oferty, warto skorzystać z usług pośrednika kredytowego ${props.data?.mysqlLocalfirms?.name || ''}. Poznaj szczegóły oferty!`}
  >
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetdata.domain}/social-media/localfirm/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
)

const Intermediary = ({ 
  data: { mysqlLocalfirms, allMysqlComments, allMysqlLocalfirms, similiarProducts }, 
  pageContext: { breadcrumbs },
  location: { pathname },
}) => {
  const {
    number_of_establishments,
    number_of_agents,
    name,
    address_name,
    isHeadquarters,
    mysqlImage,
    description,
    userrating,
    commission,
    specialization,
    advantages,
    disadvantages,
    description_advantages,
    mysqlId,
    intro_description,
  } = mysqlLocalfirms

  const [isOpenForm, setIsOpenForm] = useState(false);
  const toggleForm = () => setIsOpenForm(!isOpenForm);

  return (
    <Layout pathname={pathname}>
      <div className={["bank-header", 'intro-products2', b.dFlex, b.justifyContentCenter].join(" ")} />
      <article className="firm-page">
        <div className={[b.container, b.pt3,'breadcrumbs-mobile',b.ptLg5,"scroll-inner",'breadcrumbs-pa'].join(" ")}>
          <Breadcrumbs theme='white' items={breadcrumbs} />
        </div>
        <div className={["local-firm-page", "blog-content", "post-content"].join(" ")}>
          <div className={b.container}>
            <div className={b.row}>
              <div className={b.colLg8}>
                <h1 className={["big-title", b.mt3, b.pt1].join(' ')}>{name}</h1>
              </div>
              <div className={[b.colLg4,b.mbLg5,b.pbLg5, b.orderLgLast].join(' ')}>
                {mysqlImage && (
                  <>
                    <div className="bank-box">
                      <div className={"bank-padding-box"}>
                        <div className='bank-padding-img cursor-pointer' onClick={OutProductRedirect(mysqlLocalfirms)}>
                          <Thumbnail image={mysqlImage} alt={name} />
                        </div>
                        <div className={[b.dFlex,b.pb2,b.justifyContentCenter,b.mt3,].join(" ")}>
                          {!!userrating && mysqlLocalfirms.userrating !== "1" ? (
                            <div className="stars-container">
                              <div className="star-box" style={{ width: ` ${userrating}% ` }}>
                                <Icon size={20} icon="Star" className={b.mr1} />
                                <Icon size={20} icon="Star" className={b.mr1} />
                                <Icon size={20} icon="Star" className={b.mr1} />
                                <Icon size={20} icon="Star" className={b.mr1} />
                                <Icon size={20} icon="Star" />
                              </div>
                              <div className="empty-star-box">
                                <Icon size={20} icon="Empty-star" className={b.mr1}/>
                                <Icon size={20} icon="Empty-star" className={b.mr1}/>
                                <Icon size={20} icon="Empty-star" className={b.mr1}/>
                                <Icon size={20} icon="Empty-star" className={b.mr1}/>
                                <Icon size={20} icon="Empty-star" />
                              </div>
                            </div>
                          ) : (
                            <div className="stars-container">
                              <div>
                                <Icon size={20} icon="Empty-star" className={b.mr1}/>
                                <Icon size={20} icon="Empty-star" className={b.mr1}/>
                                <Icon size={20} icon="Empty-star" className={b.mr1}/>
                                <Icon size={20} icon="Empty-star" className={b.mr1}/>
                                <Icon size={20} icon="Empty-star" />
                              </div>
                            </div>
                          )}
                        </div>
                        {!!userrating && mysqlLocalfirms.userrating !== "1" ? (
                          <span className={[b.dFlex, b.justifyContentCenter,'font-size-20'].join(" ")}>
                            {(userrating / 20).toFixed(1)}
                          </span>
                        ) : (
                          <span className={[b.dFlex, b.justifyContentCenter,'font-size-20'].join(" ")}>
                            0.0
                          </span>
                        )}
                      </div>
                      <button className={[b.btn, "btn-light", b.mt2].join(" ")} onClick={() => { toggleForm()}}>
                        <Icon size={20} icon="Logout" className={[b.mr1, "icon-blog"].join(" ")}/>
                        <Translate tKey="Add opinion"/>
                      </button>
                      <Out className={[b.btn,"btn-success","btn-cta",b.mt2,b.textWhite,b.justifyContentCenter,b.alignItemsCenter,b.dFlex,b.fontWeightNormal].join(' ')}
                        defaultDescription="Apply" item={mysqlLocalfirms} defaultIcon="Pen"/>
                    </div>
                  </>
                )}
                <TableOfContents selector=".post-content h2[id]" withoutCalculator />
              </div>
              <div className={b.colLg8}>
                {intro_description&&(
                  <div className={['font-size-20','blackToWhite'].join(' ')} dangerouslySetInnerHTML={{ __html: intro_description }} />
                )}
                <div className={"bank-box"}>
                  <span className={"font-size-24"}><Translate tKey="Info about"/> {address_name}</span>
                  <div className={[b.row,b.mt3].join(' ')}>
                    <div className={[b.colMd6]}>
                      <div className={b.dFlex}>
                        <Icon size={40} icon="Branches" className={[b.mr2, "icon-blog"].join(" ")}/>
                        <div className={b.justifyContentStart}>
                          <small>Ilość placówek</small>
                          <p className='firm-box-text'>{number_of_establishments || '-'}</p>
                        </div>
                      </div>
                    </div>
                    <div className={[b.colMd6]}>
                      <div className={b.dFlex}>
                        <Icon size={40} icon="Co-workers" className={[b.mr2, "icon-blog"].join(" ")}/>
                        <div className={b.justifyContentStart}>
                          <small>Ilość agentów</small>
                          <p className='firm-box-text'>{number_of_agents || '-'}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={b.row}>
                    <div className={[b.colMd6]}>
                      <div className={b.dFlex}>
                        <Icon size={40} icon="License" className={[b.mr2, "icon-blog"].join(" ")}/>
                        <div className={b.justifyContentStart}>
                          <small>Obszary specjalizacji</small>
                          <p className='firm-box-text'>
                            {specialization ? specialization : '-'}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={[b.colMd6]}>
                      <div className={b.dFlex}>
                        <Icon size={40} icon="Intermediary" className={[b.mr2, "icon-blog"].join(" ")}/>
                        <div className={b.justifyContentStart}>
                          <small>Prowizja</small>
                          <p className='firm-box-text'>{commission ? commission : '-'}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div className='blue-font'>
                  <BlogContent content={description} />
                  
                  {allMysqlLocalfirms.group.length > 0 && (
                    <div className={"bank-box"}>
                      <div className={b.row}>
                        {allMysqlLocalfirms.group.map(({ edges }) =>
                          edges.map(({ node }, i) => (
                            <div className={[ b.colMd4,b.dFlex,"blackToWhite",b.my2,].join(" ")} key={i}>
                              <Icon size={40} icon="Branches" className={["icon-blog", b.mr2].join(" ")}/>
                              <Link to={resolveLocalfirmUrl(node)} className={b.fontWeightNormal}>
                                <p className={[b.pt2,b.textCapitalize].join(' ')}>
                                  {node.geo_url}
                                </p>
                              </Link>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  )}
                  
                  {isHeadquarters === 1 && advantages && disadvantages &&(
                    <AdvDis advantages={advantages || ''} disadvantages={disadvantages || ''}>
                      <h2 id="section-title-1"><Translate tKey="Adv and disadv"/> {name}</h2>
                      {description_advantages && (
                        <BlogContent content={description_advantages} />
                      )}
                    </AdvDis>
                  )}

                  <Comments items={allMysqlComments.nodes} title="standard" itemName={name} formID={mysqlId} commentType="localfirm" />
                  <CommentsForm isOpen={isOpenForm} close={() => { toggleForm(false); }} formID={mysqlId} commentType="local-firm" />
                </div>


              </div>
            </div>
          </div>
        </div>

        {similiarProducts.edges.length > 0 && (
          <div className={[b.container, b.my5, b.mtLg0, b.pbLg4].join(" ")}>
            <h2>Inni pośrednicy:</h2>
            <SimilarProducts items={similiarProducts} />
          </div>
        )}
      </article>
    </Layout>
  );
};

export default Intermediary;

export const query = graphql`
  query QueryIntermediary($mysqlId: Int!, $url: String!, $address_city: String!) {
    mysqlLocalfirms: mysqlLocalfirms(mysqlId: { eq: $mysqlId }) {
      name
      address_name
      isHeadquarters
      url
      userrating
      commission
      description
      specialization
      number_of_establishments
      number_of_agents
      workhours
      advantages
      disadvantages
      intro_description
      description_advantages
      mysqlImage {
        childImageSharp {
          gatsbyImageData(width: 189)
        }
      }
    }
    allMysqlLocalfirms(
      filter: {
        url: { eq: $url }
        type: { eq: 5 }
        mysqlId: { ne: $mysqlId }
        address_city: { ne: $address_city }
        isHeadquarters: { ne: 1 }
      }
    ) {
      group(field: address_city, limit: 1) {
        edges {
          node {
            address_city
            url
            prefix_url
            specific_url
            name
            geo_url
          }
        }
      }
    }
    faq: allMysqlFaq(
      filter: { item: { eq: $mysqlId }, type: { eq: "bank" }, answer: { ne: null } },
      sort: { fields: priority, order: DESC }
    ) {
      nodes {
        title: question
        text: answer
      }
    }
    similiarProducts: allMysqlLocalfirms(filter: {isHeadquarters: { eq: 1 }, url: { ne: $url } type: { eq: 5 }}, limit: 4) {
      edges {
        node {
          name
          userrating
          url
          prefix_url
          mysqlImage {
            childImageSharp {
              gatsbyImageData(width: 103)
            }
          }
        }
      }
    }
    allMysqlComments(filter: {type: {eq: "localfirm"}, item: {eq: $mysqlId}}, sort: {order: DESC, fields: up}) {
      nodes {
        ...CommentData 
      }
    }
  }
`;
